import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Icon,
  Divider,
  List,
  ListItem,
  ListItemLabel,
} from 'yarn-design-system-react-components';
import { TagRecord } from '@feature-flags/entities';
import {
  Button,
  ButtonAdd,
  ButtonDelete,
  ButtonSave,
  Input,
} from '../../atoms';
import { KeyboardKeys } from '../../constants';
import { ModalConfirm } from '../../organisms';
import styles from './tag-list.module.scss';

interface TagListProps {
  associatedFlagsPath?: string;
  tags: TagRecord[];
  deleteTag: (tagValue: string) => void;
  saveNewTags: (newTags: string[]) => void;
  saveUpdatedTag: (oldValue: string, newValue: string) => void;
}

export function TagList({
  associatedFlagsPath,
  tags,
  deleteTag,
  saveNewTags,
  saveUpdatedTag,
}: TagListProps) {
  const [showDeleteModal, setDeleteModalVisibility] = useState(false);

  const [newTags, setNewTags] = useState<string[]>([]);
  const [editableTags, setEditableTags] = useState<Map<number, string>>(
    new Map()
  );
  const tagToDelete = useRef<TagRecord | null>();

  const handleAddingTag = () => {
    const updatedNewTags = [...newTags, ''];

    setNewTags(updatedNewTags);
  };

  const handleNewTagChange = (value: string, newTagIndex: number) => {
    const updatedNewTags = [...newTags];

    updatedNewTags[newTagIndex] = value;
    setNewTags(updatedNewTags);
  };

  const deleteNewTag = (newTagIndex: number) => {
    const updatedTags = newTags.filter((_, index) => index !== newTagIndex);
    setNewTags(updatedTags);
  };

  const saveAndClearNewTags = async () => {
    await saveNewTags(newTags);
    setNewTags([]);
  };

  const handleEdit = (value: string, tagIndex: number) => {
    const updatedEditableTags = new Map(editableTags);

    updatedEditableTags.set(tagIndex, value);
    setEditableTags(updatedEditableTags);
  };

  const updateTag = async (tag: TagRecord, tagIndex: number) => {
    const newValue = editableTags.get(tagIndex);

    if (newValue) {
      await saveUpdatedTag(tag.value, newValue);
      const updatedEditableTags = new Map(editableTags);
      updatedEditableTags.delete(tagIndex);
      setEditableTags(updatedEditableTags);
    }
  };

  const cancelEditing = (tagIndex: number) => {
    const updatedEditableTags = new Map(editableTags);
    updatedEditableTags.delete(tagIndex);

    setEditableTags(updatedEditableTags);
  };

  const setTagToDelete = (tag: TagRecord) => {
    tagToDelete.current = tag;
  };

  const deleteThis = async () => {
    await deleteTag((tagToDelete.current as TagRecord).value);
    tagToDelete.current = null;
    setDeleteModalVisibility(false);
  };

  const newTagsAreNotEmpty = newTags.some(Boolean);

  return (
    <>
      {showDeleteModal && (
        <ModalConfirm
          onSubmit={deleteThis}
          title={` Are you sure you want to delete ${
            (tagToDelete.current as TagRecord).value
          } tag?`}
          onClose={() => setDeleteModalVisibility(false)}
        />
      )}
      <div className="space-p-b-3" data-testid="new-tag-controls">
        <ButtonAdd
          type="secondary"
          onClick={handleAddingTag}
          text="Add tag"
          className="space-m-r-2"
          data-testid="add-tag-button"
        />
        {newTagsAreNotEmpty && (
          <ButtonSave onClick={saveAndClearNewTags} type="primary" />
        )}
      </div>
      {Boolean(newTags.length) && (
        <div className="negative-margin">
          <div
            className="grid grid-cols-4 auto-rows-fr gap-4 space-p-h-3"
            data-testid="new-tags-section"
          >
            {newTags.map((newTag, newTagIndex) => (
              <div key={newTagIndex} className="flex">
                <Input
                  value={newTag}
                  onChange={(value) => handleNewTagChange(value, newTagIndex)}
                  className={`${styles['tag-value-container']}`}
                  data-testid="new-tag-input"
                />
                <ButtonDelete
                  onClick={() => deleteNewTag(newTagIndex)}
                  noText
                />
              </div>
            ))}
          </div>
          <Divider type="horizontal" className="space-m-t-2 space-m-b-3" />
        </div>
      )}

      <div className="grid grid-cols-2 auto-rows-fr gap-4">
        <List
          data-testid="tag-list"
          className={`${styles['tag-list-container']} tag-list-container`}
        >
          {!tags?.length && (
            <ListItem interactive={false}>
              <ListItemLabel>{'No tags...'}</ListItemLabel>
            </ListItem>
          )}
          {(tags || []).map((tag, tagIndex) => {
            const editableValue = editableTags.get(tagIndex);
            const tagValueWasChanged =
              (editableValue || editableValue === '') &&
              editableValue !== tag.value;

            return (
              <ListItem
                key={tagIndex}
                interactive={false}
                className={`${styles['tag-list-item']} block space-p-h-0 border-none`}
              >
                <div className="flex">
                  <Input
                    value={editableValue ?? tag.value}
                    onChange={(value) => handleEdit(value, tagIndex)}
                    onKeyDown={(event: KeyboardEvent) => {
                      if (event.key === KeyboardKeys.Enter) {
                        return updateTag(tag, tagIndex);
                      }

                      if (
                        event.key === KeyboardKeys.Escape &&
                        tagValueWasChanged
                      ) {
                        return cancelEditing(tagIndex);
                      }
                    }}
                    className={`${styles['tag-value-container']} space-m-r-2`}
                  />
                  {tagValueWasChanged && (
                    <Button
                      type="icon"
                      className="space-m-r-2"
                      onClick={() => cancelEditing(tagIndex)}
                      data-testid={`cancel-edit-${tagIndex}`}
                    >
                      <Icon name="close" size="md" />
                    </Button>
                  )}
                  {tagValueWasChanged && editableValue && (
                    <ButtonSave
                      type="primary"
                      onClick={() => updateTag(tag, tagIndex)}
                      className="space-m-r-2"
                    />
                  )}
                  <ButtonDelete
                    onClick={() => {
                      setTagToDelete(tag);
                      setDeleteModalVisibility(true);
                    }}
                    noText
                  />
                </div>
                {tag.usedInFlags && associatedFlagsPath && (
                  <div className="space-m-b-3">
                    <Link to={`${associatedFlagsPath}?tag=${tag.value}`}>
                      Tag is used in {tag.usedInFlags.length}{' '}
                      {tag.usedInFlags.length === 1 ? 'flag' : 'flags'}
                    </Link>
                  </div>
                )}
              </ListItem>
            );
          })}
        </List>
      </div>
    </>
  );
}
